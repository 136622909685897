@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@300&display=swap');

::-webkit-scrollbar {
  width: 4px;
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}
.loading{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: 'Sarabun', sans-serif;
}
.logo{
    width: 90px;
    object-fit: contain;
}
.banner-content{
    margin-top: 30px;
}
.banner-content h1{
    color: black;
}
.contact{
    margin-top: 50px;
}
.burger{
    outline: none !important;
}
.burger:focus{
    outline: none !important;
}
/* .div-nav{
    max-height: 100px !important;
    background-color: blue !important;
} */
.book-a-meeting{
    background-color: black;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
}
/* .home .text-content{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 200px);
}
.home .text-content h1{
    font-size: 60px;
}
.home .text-content p{
    width: 1000px;
    text-align: center;
}
.home .text-content button{
    background-color: black;
    padding: 10px;
    color: white;
    border-radius: 10px;
    margin: 10px;
    width: 200px;
} */

.about-us-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.banner-text{
    color: black;
}
.about-us div{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 200px;
}
.about-us div h1{
    font-size: 60px;
}
.about-us .our-mission h2{
    font-size: 30px;
    margin: 20px;
}
.about-us .content{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.about-us .content .our-mission{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.about-us .our-mission p{
    width: 1000px;
    margin: 20px;
}
.about-us .our-approach h2{
    font-size: 30px;
    margin: 20px;
}
.about-us .content{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.about-us .content .our-approach{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.about-us .our-approach p{
    width: 1000px;
    margin: 20px;
}
.services .services-content{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.services{
    margin-top: 100px;
}
.services h1{
    font-size: 50px;
}
.service{
    width: 400px;
    height: 200px;
    padding: 10px;
    margin: 1em;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1); */
    box-shadow: 0px 3px 15px -3px rgba(0,0,0,0.1);
    border-radius: 10px;
}
.schedule-meeting{
    margin-top: 100px;
}
.service h2{
    text-align: center;
}
.service p{
    text-align: center;
}
.service i{
    font-size: 50px;
}
.meeting{
    margin-top: 100px;
    height: 400px;
    width: 100%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}
.meeting h1{
    color: white;
    font-size: 50px;
    font-weight: bold;
}
.meeting p{
    color: white;
}
.meeting button{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    width: 200px;
    margin: 20px;
}
.accordions{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    padding: 10px;
}
.accordions-content{
    width: 1000px;
}
footer{
    margin-top: 100px;
}
  
.why-us{
      padding: 20px;
}

  @media screen and (max-width: 800px){
    .desktop-nav{
      display: none;
  }
}